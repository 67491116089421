import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/sections/footer"

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Not found |" />
		<Navigation />
		<h1>Não encontrado</h1>
		<p>Essa página não existe.</p>
		<Footer />
	</Layout>
)

export default NotFoundPage
